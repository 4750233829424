



























import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import LoadWidgetComponent from '@/components/LoadWidgetComponent.vue';

/* eslint-disable @typescript-eslint/camelcase,no-underscore-dangle */
@Component({
  components: { LoadWidgetComponent },
})
export default class EpDashboardLayoutWidget extends mixins(VueBaseWidget, VueRegisterStoreWidget) {
  created(): void {
    this.setDataConfig();
  }
}
